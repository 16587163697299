import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { useLoadScript } from '@react-google-maps/api';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';
import { GatsbyImage } from 'gatsby-plugin-image';
import DefaultLayout from '../layouts/Default';
import Map from '../components/Map/map';
import Container from '../components/Container';
import SEO from '../components/SEO';
import ImageWithCopy from '../components/CMSBlocks/ImageWithCopy';
import VideoWithCopy from '../components/CMSBlocks/VideoWithCopy';
import MetricsPanel from '../components/CMSBlocks/MetricsPanel';
import ContactCard from '../components/CMSBlocks/ContactCard';
import BackgroundVideo from '../components/CMSBlocks/VideoWithCopy/background';
import IntroBanner from '../components/CMSBlocks/IntroBanner';

const Home = ({ data }) => {
	const { homepageElements, heroTitle, heroSubtitle, contactCards, cardsTitle, trustCarousel } =
		data.datoCmsHomepage;
	const allCompanies = data.allDatoCmsCompany.nodes;

	const sliderSettings = {
		mobileFirst: true,
		dots: false,
		infinite: true,
		speed: 1000,
		variableWidth: false,
		autoplay: true,
		slidesToScroll: 1,
		slidesToShow: 5,
		responsive: [
			{
				breakpoint: 900,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1150,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1300,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
				},
			},
		],
	};

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_KEY,
	});

	const pageContent = useRef(null);

	const scrollToContent = () => pageContent.current.scrollIntoView({ behavior: 'smooth' });

	const location = useLocation();

	return (
		<div className="overflow-hidden">
			<SEO
				path={location.pathname}
				title="Peninsula Group Global"
				description="Peninsula Group Global is the leading provider of Employment Law, HR and Health & Safety services in the UK, Ireland, Australia, New Zealand, and Canada."
			/>
			<div className="h-screen">
				{isLoaded && (
					<>
						<Map
							heroTitle={heroTitle}
							heroSubtitle={heroSubtitle}
							data={allCompanies}
						/>
						<div
							className="flex flex-col items-center justify-center py-8 text-center "
							style={{ height: '11vh' }}
						>
							<button
								className="flex flex-col items-center justify-center duration-75 text-brand-200 hover:text-brand-100"
								type="button"
								onClick={scrollToContent}
							>
								<svg
									width="40"
									height="30"
									viewBox="0 0 33 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M32.625 2.12506C32.625 1.38298 32.0233 0.781311 31.2813 0.781311C30.8941 0.781311 30.5461 0.946088 30.3008 1.20812C30.2976 1.21131 16.5 16.2615 16.5 16.2615C16.5 16.2615 2.70238 1.21131 2.69918 1.20812C2.45395 0.946088 2.10609 0.781311 1.71875 0.781311C0.976664 0.781311 0.375 1.38298 0.375 2.12506C0.375 2.4699 0.506015 2.7835 0.719672 3.02134C0.722863 3.02504 15.5095 19.1581 15.5095 19.1581C15.7641 19.4356 16.1234 19.5938 16.5 19.5938C16.8766 19.5938 17.236 19.4358 17.4905 19.1581C17.4905 19.1581 32.2771 3.02504 32.2803 3.02134C32.494 2.78333 32.625 2.4699 32.625 2.12506Z"
										fill="currentColor"
									/>
								</svg>
								Find out more
							</button>
						</div>
					</>
				)}
			</div>
			<div ref={pageContent}>
				{homepageElements.map((el) => (
					<div key={nanoid()}>
						{el.blocktype === 'IntroBanner' && (
							<IntroBanner
								primaryImage={el.primaryImage.gatsbyImageData}
								primaryImageAlt={el.primaryImage.alt}
								text={el.textNode.childMdx.body}
								secondaryImage={el.secondaryImage?.gatsbyImageData}
								secondaryImageAlt={el.secondaryImage?.alt}
								bannerText={el.bannerText}
								title={el.title}
							/>
						)}
						{el.blocktype === 'ImageWithCopy' && (
							<ImageWithCopy
								imgSrc={el.imageWithCopyImage.url}
								alt={el.imageWithCopyImage.alt}
								text={el.textNode.childMdx.body}
								imagePosition={el.imagePosition}
								bgColor={el.backgroundColour}
							/>
						)}
						{el.blocktype === 'VideoWithCopy' && (
							<VideoWithCopy
								text={el.textNode.childMdx.body}
								videoPosition={el.videoPosition}
								youtubeId={el.youtubeId}
								vimeoId={el.vimeoId}
								bgColor={el.backgroundColour}
								videoSubText={el.videoSubText}
							/>
						)}
						{el.blocktype === 'BackgroundVideo' && (
							<BackgroundVideo
								text={el.textNode.childMdx.body}
								videoUrl={el.videoUrl}
								header={el.header}
								backupImage={el.backupImage.url}
							/>
						)}
						{el.blocktype === 'MetricsPanel' && (
							<MetricsPanel
								bgdImg={el.backgroundImage?.url}
								title={el.title}
								text={el.textNode.childMdx.body}
								text2={el.text2Node.childMdx.body}
								hasText={el.text}
								hasText2={el.text2}
								figure1={el.countupNumber1}
								figure2={el.countupNumber2}
							/>
						)}
					</div>
				))}

				<section className="relative flex flex-col bg-no-repeat bg-cover bg-contact-card-gradient-smScreen bg-secondary-100 md:block">
					<div className="relative bg-center bg-contact-card-gradient-lgScreen before:md:absolute before:md:h-full before:md:w-full before:md:top-2.5 before:md:left-0 after:md:content-['']  md:bg-black">
						<Container className=" md:px-3 lg:px-6 md:p-8">
							<div className="relative w-full mb-6 text-center">
								<h3 className="mb-3 text-2xl md:text-3xl text-bold text-secondary-300 md:text-white">
									{cardsTitle}
								</h3>

								<p className="mb-2 text-secondary-300 md:text-white">
									To contact our global media relations team, please email{' '}
									<a
										className="font-bold duration-75 hover:underline text-brand-100"
										href="mailto:uk.pressoffice@peninsulagroupglobal.com"
									>
										uk.pressoffice@peninsulagroupglobal.com
									</a>
								</p>
								<p className="text-secondary-300 md:text-white">
									Please use this email for media requests only. Due to the volume
									of enquiries, please help us by specifying your deadline.
								</p>
							</div>

							<div className="grid w-full grid-cols-1 gap-6 md:mt-12 lg:grid-cols-2 auto-rows-fr">
								{contactCards.map((el) => (
									<div key={nanoid()} className="auto-rows-fr">
										<ContactCard
											bgdImg={el.backgroundImage.url}
											header={el.header}
											address={el.addressNode.childMdx.body}
											phone={el.phoneNumber}
											email={el.email}
										/>
									</div>
								))}
							</div>
						</Container>
					</div>
				</section>

				<section className="py-10">
					<h3 className="m-auto mb-0 text-3xl font-bold text-center md:mb-8">
						Latest Awards
					</h3>
					<div>
						<div className="relative p-6 py-4 text-center md:px-0 ">
							<div className="container grid grid-cols-2 gap-12 p-6 pt-10 mx-auto text-center justify-items-center sm:grid-cols-2 md:hidden">
								{trustCarousel.map((badge) => (
									<div key={nanoid()}>
										<GatsbyImage
											image={badge.gatsbyImageData}
											alt={badge.alt || ''}
											loading="lazy"
											objectFit="contain"
										/>
									</div>
								))}
							</div>

							<div className="hidden mt-2 mb-10 overflow-hidden md:block hover:cursor-pointer">
								<Slider {...sliderSettings}>
									{trustCarousel.map((badge) => (
										<div
											className="item text-center !flex justify-center"
											key={nanoid()}
										>
											<img
												key={nanoid()}
												className="h-32"
												src={badge.url}
												alt={badge.alt}
											/>
										</div>
									))}
								</Slider>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
};

const WrappedHome = (props) => (
	<DefaultLayout fullWidth fixedNav>
		<Home {...props} />
	</DefaultLayout>
);

export default WrappedHome;

export const query = graphql`
	query homepage {
		datoCmsHomepage {
			heroTitle
			heroSubtitle
			homepageElements {
				... on DatoCmsIntroBanner {
					blocktype
					bannerText
					title
					textNode {
						childMdx {
							body
						}
					}
					primaryImage {
						gatsbyImageData(placeholder: BLURRED)
						alt
					}
					secondaryImage {
						gatsbyImageData(placeholder: BLURRED)
						alt
					}
				}
				... on DatoCmsImageWithCopy {
					blocktype
					imagePosition
					backgroundColour
					textNode {
						childMdx {
							body
						}
					}
					imageWithCopyImage {
						url
						alt
					}
				}
				... on DatoCmsVideoWithCopy {
					blocktype
					videoPosition
					youtubeId
					vimeoId
					videoSubText
					backgroundColour
					textNode {
						childMdx {
							body
						}
					}
				}
				... on DatoCmsMetricsPanel {
					blocktype
					title
					text
					textNode {
						childMdx {
							body
						}
					}
					text2
					text2Node {
						childMdx {
							body
						}
					}
					backgroundImage {
						url
					}
					countupNumber1
					countupNumber2
				}

				... on DatoCmsBackgroundVideo {
					blocktype
					header
					textNode {
						childMdx {
							body
						}
					}
					videoUrl
					backupImage {
						url
					}
				}
			}
			cardsTitle
			contactCards {
				... on DatoCmsContactCard {
					addressNode {
						childMdx {
							body
						}
					}
					blocktype
					email
					header
					phoneNumber
					backgroundImage {
						url
					}
				}
			}
			trustCarousel {
				url
				alt
				gatsbyImageData(
					layout: CONSTRAINED
					width: 200
					imgixParams: { fm: "webp", auto: "compress" }
				)
			}
		}
		allDatoCmsCompany {
			nodes {
				...Map
			}
		}
	}
`;
