import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import Container from '../../Container';
import CopyBlock from '../../CopyBlock';

const BackgroundVideo = ({ videoUrl, backupImg, header, text }) => {
	const [loadVideo, setLoadVideo] = useState(false);
	useEffect(() => {
		if (typeof window !== 'undefined') {
			if (window.innerWidth > 768) {
				setLoadVideo(true);
			}
		}
	}, []);

	return (
		<section className="relative flex items-center justify-center w-full py-4 text-center bg-no-repeat bg-cover bg-video-gradient h-fit md:py-24">
			{loadVideo && (
				<LazyLoad once>
					<video
						autoPlay
						loop
						muted
						poster={`${backupImg}`}
						className="absolute top-0 left-0 object-cover w-full h-full bg-video-gradient"
					>
						<source src={`${videoUrl}`} type="video/mp4" />
					</video>
				</LazyLoad>
			)}

			<Container className="z-10 text-white ">
				<div className="flex flex-col text-left md:flex-row ">
					<div className="w-full md:w-1/3 md:pr-8">
						<h2 className="mb-12 text-xl text-white md:text-2xl">{header}</h2>
					</div>
					<div className="w-full text-white md:w-2/3 text-white-p">
						<CopyBlock>{text}</CopyBlock>
					</div>
				</div>
			</Container>
		</section>
	);
};

export default BackgroundVideo;
