// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import ReactDOM from 'react-dom';
import { GoogleMap, Marker, MarkerClusterer } from '@react-google-maps/api';
import { GatsbyImage } from 'gatsby-plugin-image';
import { nanoid } from 'nanoid';
import cn from 'classnames';
import { graphql } from 'gatsby';
import Places from './places';
import ChevronRightXs from '../../icons/ChevronRightXs.svg';
import Container from '../Container';
import Cross from '../../icons/Cross.svg';

const Wrapper = React.forwardRef(({ children, onClose, classname, ...props }, ref) =>
	ReactDOM.createPortal(
		<div
			className={`${classname} slide-drawer duration-500 rounded-md shadow-lg p-4 absolute md:fixed top-0 right-0 z-50 h-screen bg-white border-secondary-400 border-l md:border w-[90vw] md:top-1/2 md:right-unset md:left-1/2 md:h-fit md:max-w-md md-transform-center`}
			{...props}
			ref={ref}
		>
			<div className="relative">
				<div className="absolute justify-end inline-block -right-1 -top-1 ">
					<button type="button" onClick={onClose}>
						<Cross className="text-black duration-100 w-7 hover:text-secondary-300" />
					</button>
				</div>
			</div>
			<div className="pt-2">{children}</div>
		</div>,
		document.body
	)
);

const Map = ({ heroTitle, heroSubtitle, data }) => {
	const [selectedElement, setSelectedElement] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);

	// eslint-disable-next-line no-unused-vars
	const [office, setOffice] = useState();

	const InfoWindow = useRef();
	const mapRef = useRef();
	const [baseLattitude, setBaseLattitude] = useState(18.830725);
	const [baseLongitude, setBaseLongitude] = useState(46.568683);
	const [clusterMarkerSize, setClusterMarkerSize] = useState(50);

	const [zoomLevel, setZoomLevel] = useState();
	const [defaultZoomLevel, setDefaultZoomLevel] = useState();
	const [mapRendered, setMapRendered] = useState(false);
	const [mobileMapInteraction, setMobileMapInteraction] = useState(false);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			if (window.innerWidth < 1900) {
				setDefaultZoomLevel(2);
			} else {
				setDefaultZoomLevel(3);
			}

			if (window.innerWidth < 768) {
				setBaseLattitude(30.830725);
				setBaseLongitude(-25.568683);
				setClusterMarkerSize(45);
			}
		}
	}, []);

	const center = useMemo(
		() => ({ lat: baseLattitude, lng: baseLongitude }),
		[baseLattitude, baseLongitude]
	);

	const [maintainTilt, setMaintainTilt] = useState(45);
	const options = useMemo(
		() => ({
			mapId: process.env.GATSBY_GOOGLE_MAPS_ID,
			clickableIcons: true,
			zoomControl: false,
			mapTypeControl: false,
			scaleControl: false,
			streetViewControl: false,
			rotateControl: false,
			fullscreenControl: false,
			tilt: maintainTilt,
			minZoom: 2,
			maxZoom: 7,
			backgroundColor: '#ffffff',
		}),
		[maintainTilt]
	);

	// eslint-disable-next-line no-return-assign
	const onLoad = useCallback((map) => (mapRef.current = map), []);
	const onTilesLoaded = () => setMapRendered(true);
	const onZoomChanged = () => setSelectedElement(null);

	const pinSelect = (element) => {
		setSelectedElement(element);
		setModalOpen(true);
		setMobileMapInteraction(true);
	};

	const pinClose = () => {
		setSelectedElement(null);
		setModalOpen(false);
	};

	// Click outside infoWindow hook
	function useOnClickOutside(ref, handler) {
		useEffect(() => {
			const listener = (event) => {
				// Do nothing if clicking ref's element or descendent elements
				if (!ref.current || ref.current.contains(event.target)) {
					return;
				}
				handler(event);
			};
			document.addEventListener('mousedown', listener);
			document.addEventListener('touchstart', listener);
			return () => {
				document.removeEventListener('mousedown', listener);
				document.removeEventListener('touchstart', listener);
			};
		}, [ref, handler]);
	}

	useOnClickOutside(InfoWindow, () => {
		setModalOpen(false);
		setSelectedElement(false);
	});

	return (
		<>
			{modalOpen && (
				<div
					className="fixed top-0 bottom-0 left-0 right-0 z-50 flex flex-col items-center justify-center w-full p-6 bg-lightbox"
					aria-label="Close info window"
				/>
			)}
			<div
				className="relative h-[85vh] bg-white md:h-[89vh]"
				onClick={() => {
					setMobileMapInteraction(true);
				}}
				onKeyDown={() => {
					setMobileMapInteraction(true);
				}}
				role="button"
				tabIndex={0}
			>
				<GoogleMap
					zoom={zoomLevel || defaultZoomLevel}
					center={center}
					mapContainerClassName="w-full h-full m-auto"
					options={options}
					onLoad={onLoad}
					onTilesLoaded={onTilesLoaded}
					onZoomChanged={onZoomChanged}
				>
					<Places
						setOffice={(position) => {
							setOffice(position);
							mapRef.current?.panTo(position);
						}}
						setZoomLevel={setZoomLevel}
						defaultZoomLevel={defaultZoomLevel}
						baseLattitude={baseLattitude}
						baseLongitude={baseLongitude}
					/>
					<MarkerClusterer
						clusterClass="animation-pulse rounded-full"
						styles={[
							{
								url: 'https://www.datocms-assets.com/75137/1662036809-peninsulagroupclusterblue.png',
								height: clusterMarkerSize,
								width: clusterMarkerSize,
								textSize: 1,
							},
						]}
						onClick={() => {
							setMobileMapInteraction(true);
							setMaintainTilt(45);
							setZoomLevel(7);
						}}
						onKeyDown={() => {
							setMobileMapInteraction(true);
						}}
						maxZoom={5}
						title="Click to see company offices"
					>
						{(clusterer) => (
							<div>
								{data.map((element) => (
									<div key={nanoid()}>
										<Marker
											clusterer={clusterer}
											key={element.id}
											title={element.companyName}
											position={{
												lat: element.lattitude,
												lng: element.longitude,
											}}
											onClick={() => pinSelect(element)}
											icon={element.mapIcon}
										/>
									</div>
								))}
								{selectedElement && (
									<>
										<CSSTransition
											nodeRef={InfoWindow}
											in={modalOpen}
											timeout={1000}
											classNames="my-modal"
										>
											<Wrapper onClose={pinClose} ref={InfoWindow}>
												<div className="max-w-2xl p-2 overflow-hidden bg-white">
													<div className="flex justify-center">
														<GatsbyImage
															image={
																selectedElement.logo
																	?.gatsbyImageData
															}
															className="w-32 mb-4"
															alt={selectedElement.logo?.alt || ''}
															loading="lazy"
															objectFit="contain"
														/>
													</div>

													<h2 className="mb-3 font-normal leading-6 text-left text-md text-colors-primary-700">
														{selectedElement.companyName}
													</h2>
													<p className="mb-3 text-xs text-left text-black">
														{selectedElement.excerpt}
													</p>
													<a
														href={selectedElement.website}
														target="_blank"
														className="flex flex-row items-center py-1 pl-3 pr-2 mb-1 text-xs font-bold duration-100 bg-white border w-max border-colors-primary-700 rounded-3xl text-colors-primary-700 flex-nowrap hover:border-colors-primary-600 hover:text-colors-primary-600"
														rel="noreferrer"
													>
														Visit website
														<span className="w-3 h-3 mt-1 ml-2">
															<ChevronRightXs />
														</span>
													</a>
												</div>
											</Wrapper>
										</CSSTransition>
									</>
								)}
							</div>
						)}
					</MarkerClusterer>
					)
				</GoogleMap>
				<Container className="relative py-0 md:!py-0 md:px-3">
					<div
						className={cn(
							`absolute left-0 z-10 max-w-full px-3 py-2 md:bg-opacity-90 bg-white md:max-w-sm xxl:max-w-lg bottom-0 md:bottom-6 md:left-3`,
							{
								'hidden md:block': mobileMapInteraction,
								block: !mobileMapInteraction,
							}
						)}
					>
						<h1 className="mb-2 text-lg font-normal leading-none md:text-3xl lg:text-4xl xxl:text-5xl">
							{heroTitle}
						</h1>
						<h2 className="text-sm font-normal leading-5 md:text-md xxl:text-xl">
							{heroSubtitle}
						</h2>
					</div>
				</Container>
			</div>
			{!mapRendered && (
				<>
					<div className="absolute top-0 left-0 z-30 w-full h-screen bg-white" />
					<div className="absolute top-0 left-0 z-40 w-full h-screen bg-secondary-400" />
				</>
			)}
		</>
	);
};

export const query = graphql`
	fragment Map on DatoCmsCompany {
		fullDescriptionNode {
			childMdx {
				body
			}
		}
		excerpt
		country
		companyName
		mapIcon {
			url
		}
		longitude
		lattitude
		logo {
			gatsbyImageData(layout: CONSTRAINED, width: 180, imgixParams: { auto: "compress" })
			url
			alt
		}
		website
	}
`;

export default Map;
