import React, { useState } from 'react';
import cn from 'classnames';
import { nanoid } from 'nanoid';
import ChevronRight from '../../icons/chevron-right.svg';
import Container from '../Container';

export default function Places({
	setOffice,
	setZoomLevel,
	baseLattitude,
	baseLongitude,
	defaultZoomLevel,
}) {
	const goToLocale = (latLng) => {
		setOffice(latLng);
	};

	const [sortByActive, setSortByActive] = useState(false);
	const [selected, setDesktopSelected] = useState(false);
	const [mobileSortByClicked, setMobileSortByClicked] = useState(false);

	const buttonData = [
		{
			label: 'All',
			lat: baseLattitude,
			lng: baseLongitude,
			finalZoomLevel: defaultZoomLevel,
		},
		{
			label: 'UK',
			lat: 53.489527063618745,
			lng: -2.2410747794385224,
			finalZoomLevel: 5,
		},
		{
			label: 'Republic of Ireland',
			lat: 53.3570052,
			lng: -6.2301171,
			finalZoomLevel: 5,
		},
		{
			label: 'Canada',
			lat: 43.6448963,
			lng: -79.3851667,
			finalZoomLevel: 4,
		},
		{
			label: 'Australia',
			lat: -33.9815141,
			lng: 150.42775417004276,
			finalZoomLevel: 4,
		},
		{
			label: 'New Zealand',
			lat: -35.9815141,
			lng: 180.42775417004276,
			finalZoomLevel: 4,
		},
	];

	return (
		<Container className="relative py-4 md:!py-0 md:px-3">
			<div
				className={cn(
					`absolute left-0 z-10 px-3 pt-3 overflow-hidden transition-all duration-300 bg-white shadow-lg md:shadow-none  md:p-0 md:bg-transparent top-[6rem] md:top-32 md:left-3 controls w-full border-2 border-white`,
					{
						'md:border-none': mobileSortByClicked,
						'animate-borderGlow md:animate-none md:border-none': !mobileSortByClicked,
					}
				)}
			>
				<h3
					className="flex flex-row justify-between w-full mb-2 font-normal md:hidden flex-nowrap"
					onClick={() => {
						if (sortByActive === true) {
							setSortByActive(false);
						} else {
							setSortByActive(true);
						}
						setMobileSortByClicked(true);
					}}
					onKeyDown={() => {
						if (sortByActive === true) {
							setSortByActive(false);
						} else {
							setSortByActive(true);
						}
						setMobileSortByClicked(true);
					}}
					// eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
					role="button"
					tabIndex="0"
				>
					<span>Select country</span>
					<ChevronRight
						className={cn(`mt-[6px] mr-1 transition-all md:hidden`, {
							'-rotate-90': sortByActive === true,
							'rotate-90': sortByActive === false,
						})}
					/>
				</h3>
				<div
					className={cn(
						`flex flex-col md:flex-row flex-nowrap h-full overflow-hidden transition-all duration-300 md:bg-transparent md:max-h-96`,
						{
							'max-h-96 mb-1': sortByActive === true,
							'max-h-0 mb-0': sortByActive === false,
						}
					)}
				>
					{buttonData.map((data, i) => (
						<div className="inline-block mx-1" key={nanoid()}>
							<button
								type="button"
								id={i}
								onClick={() => {
									goToLocale({ lat: data.lat, lng: data.lng });
									setZoomLevel(defaultZoomLevel === 3 ? 2 : 3);
									setTimeout(() => {
										setZoomLevel(data.finalZoomLevel);
									}, 500);
									setSortByActive(false);
									setDesktopSelected(i);
								}}
								className={cn(
									`inline-block w-full px-3 py-2 font-bold text-center duration-200 bg-white border-b cursor-pointer md:first:ml-0 md:ml-3 md:mx-0 whitespace-nowrap border-secondary-400 md:my-1 hover:bg-brand-100 hover:text-white focus:text-white focus:bg-brand-100 md:border md:w-fit text-brand-400`,
									{
										'bg-brand-100 !text-white': selected === i,
									}
								)}
							>
								{data.label}
							</button>
						</div>
					))}
				</div>
			</div>
		</Container>
	);
}
